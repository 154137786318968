import React from "react";
import "./Reasons.css";
import image1 from "../../assets/image1.jpg";
import image2 from "../../assets/image2.jpg";
import image3 from "../../assets/image3.jpg";
import image4 from "../../assets/image4.jpg";
import puma from "../../assets/puma.jpg";
import adidas from "../../assets/adidas.png";
import nike from "../../assets/nike.png";
import tick from "../../assets/tick.png";

const Reasons = () => {
  return (
    <div className="Reasons" id="reasons">
      <div className="left-r">
        <img src={image1} alt="" />
        <img src={image2} alt="" />
        <img src={image3} alt="" />
        <img src={image4} alt="" />
      </div>

      <div className="right-r">
        <span>Several Reasons</span>
        <div>
          <span className="outline-text">Why</span>
          <span> choose Fitfinity?</span>
        </div>

        <div className="details-r">
            <div>
                <img src={tick} alt=""/>
                <span>OVER 20+ TOP-NOTCH COACHES</span>
            </div>
            <div>
                <img src={tick} alt=""/>
                <span>IMPROVE TRAINING: SMARTER, FASTER</span>
            </div>
            <div>
                <img src={tick} alt=""/>
                <span>STATE-OF-THE-ART EQUIPMENT</span>
            </div>
            <div>
                <img src={tick} alt=""/>
                <span>DELIGHTFUL COMMUNITY ATMOSPHERE</span>
            </div>
        </div>
        <span style={{
            color: 'var(--gray)', 
            fontWeight: "normal",
        }}>OUR PARTNERS</span>
        <div className="partners">
        <img src={puma} alt=""/>
        <img src={adidas} alt=""/>
        <img src={nike} alt=""/>
        </div>
      </div>
    </div>
  );
};

export default Reasons;
