import image1 from '../assets/image1T.jpg';
import image2 from '../assets/image2T.jpg';
import image3 from '../assets/image3T.png';

export const testimonialsData = [
    {
        image: image1,
        review: "The equipment is top-notch and always clean, and the staff are friendly and knowledgeable. ",
        name: "JOEY BING",
        stat: 'ACTOR'
    },

    {
        image: image2,
        review: "I recently joined this gym and I have to say, it's one of the best decisions I've made for my fitness journey. ",
        name: "MONICA GREEN",
        stat: 'CHEF'
    },

    {
        image: image3,
        review: "The atmosphere is so positive and motivating, and the staff really care about helping you reach your goals. ",
        name: "PHOEBE GELLER",
        stat: 'MASSEUSE'
    },

];