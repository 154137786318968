export const programsData = [
    {
        image: (
            <i class="fa-solid fa-dumbbell"></i>
        ),
        heading: "Strength Training",
        details: " Unleash your inner warrior and push through the pain to unlock the strength within."
    },
    {
        image:(
            <i class="fa-solid fa-heart-circle-bolt"></i>
        ),
        heading: "Cardio Training",
        details: " Get your heart pumping and sweat pouring as you burn away the calories."
        
    },
    {
        image:(
            <i class="fa-solid fa-fire"></i>
        ),
        heading: "Fat Burning",
        details: " Melt away those extra pounds and reveal a leaner."

    },
    
    {
        image:(
            <i class="fa-solid fa-heart-pulse"></i>
        ),
        heading: "Health Fitness",
        details: " Transform your body and improve your overall health and wellness."

    },
];