import React from 'react'
import './Plans.css'
// import {whiteTick} from "../../assets/whiteTick.png"
import {plansData} from '../../data/plansData'

const Plans = () => {
  return (
    <div className='plans-c'>
      <div className='blur blur-p1'></div>
      <div className='blur blur-p2'></div>
      <div className='p-header' style={{gap: '2rem'}}>
        <span className='outline-text'>READY TO BEGIN</span>
        <span>YOUR JOURNEY</span>
        <span className='outline-text'>WITH FITFINITY</span>
      </div>

      <div className='plans'>
        {plansData.map((plan, i) => (
            <div className='plan' key={i}>
                {plan.icon}
                <span>{plan.name}</span>
                <span>Rs. {plan.price} </span>

                <div className='features'>
                    {plan.features.map((feature, i) => (
                        <div className='feature'>
                           {/* <img src={whiteTick} alt=''/>*/}
                           ✨
                            <span key={i}> {feature}</span>
                        </div>
                    ))}
                </div>

                <div>
                    <span>See more benefits -> </span>
                </div>
                <button className='btn'>Join Now</button>
            </div>
        ))}
      </div>
    </div>
  )
}

export default Plans
