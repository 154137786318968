import React, { useRef } from "react";
import "./Join.css";
import emailjs from "@emailjs/browser";
const Join = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_kt0mfph",
        "template_qizlkmw",
        form.current,
        "Amj99nS4RszsQoB8_"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div className="Join" id="join-us">
      <div className="left-j">
        <hr />
        <div>
          <span className="outline-text">READY TO</span>
          <span>LEVEL UP</span>
          <span>YOUR BODY</span>
          <span className="outline-text">WITH US?</span>
        </div>
      </div>
      
        {/*<form ref={form} className='email-c' onSubmit={sendEmail}>
            <input type='email' name='user_email' placeholder='Enter your Email address'/>
            <input type='text' name='message' placeholder='Message if any'/>
            <button className='btn btn-j'>Join Now</button>
  </form>*/}
  <h2 class="heading">
  So, Contact <span className=" outline-text">US!</span>
  </h2>
  <section class="contact" id="contact">
          <form ref={form} className="email-c" onSubmit={sendEmail}>
          <div className="wholeform">
            <div class="input-box">
              <input type="text" placeholder="Full Name" name="user_name" class="input"/>
              <input
                type="email"
                placeholder="Email Address"
                name="user_email"
              />
            </div>
            <textarea
              name="message"
              id=""
              cols="30"
              rows="10"
              placeholder="Your Message"
            ></textarea>
            <button className="btn btn-j">Join Now</button>
            </div>
          </form>
        </section>
      </div>
  );
};

export default Join;
