import './plansData.css'

export const plansData = [
    {
        icon: (
            <i class="fa-solid fa-heart-circle-plus"></i>
        ),
        name: "BASIC PLAN",
        price: "999",
        features: [
            "1.5 hours of exercises",
            "Complimentary coaching sessions",
            "Access to the community",
        ],
    },
    {
        icon: (
            <i class="fa-solid fa-crown"></i>
        ),
        name: "PREMIUM PLAN",
            price: "1499",
            features: [
                "4 hours of exercises",
                "Complimentary coaching sessions",
                "Access to minibar",
            ],
    },
    {
        icon: (
            <i class="fa-solid fa-dumbbell"></i>
        ),
        name: "PRO PLAN",
        price: "2999",
        features: [
            "7 hours of exercises",
            "Complimentary private coaching sessions",
            "Fitness Merchandises at no cost",
        ],
    },
];