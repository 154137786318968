import React from "react";
import "./Hero.css";
import Header from "../Header/Header";
import first_photo0 from "../../assets/first_photo0.png";
import Heart from "../../assets/heart.jpg";
import Calories from "../../assets/calories.png";
// import { motion } from "framer-motion";

const Hero = () => {
  // const transition = { type: "spring", duration: 2 };
  // const mobile =window.innerWidth<=768 ? true:false;
  return (
    <div className="hero" id="home">
      <div className="blur blur-h"></div>
      <div className="left">
        <Header />
        <div className="ad">
          {/*<motion.div
            initial={{ left: mobile? "178px":"465px" }}
            whileInView={{ left: "8px" }}
            transition={{ ...transition, type: "tween" }}
  ></motion.div>*/}
          <span>
            The fitness center that stands out as the best of the best
          </span>
        </div>

        <div className="text">
          <div>
            <span className="outline-text">SHAPE </span>
            <span>YOUR </span>
          </div>
          <div>
            <span className="body">BODY PHYSIQUE</span>
          </div>
          <div>
            <span>
              We are here to assist you in achieving your desired physique and
              living your life to the fullest
            </span>
          </div>
        </div>

        {/*<div className="numbers">
          <div>
            <span>20+</span>
            <span>Top-notch Coaches</span>
          </div>
          <div>
            <span>350+</span>
            <span>Happy Fitfiners</span>
          </div>
          <div>
            <span>15+</span>
            <span>Fitness Programs</span>
          </div>
</div>*/}

        <div className="buttons">
          <buttons className="btn">Get Started</buttons>
          <buttons className="btn">Learn More</buttons>
        </div>
      </div>
      <div className="right">
        <button className="btn">Join Now</button>

        <div //motion.div
          //transition={transition}
          //whileInView={{ right: "4rem" }}
          //initial={{ right: "-1rem" }}
          className="heart"
        >
          <img src={Heart} alt="" className="heart-img" />
          <span>Heart Rate</span>
          <span>100 bpm</span>
        </div>

        <img src={first_photo0} alt="" className="first_photo" />

        <div //motion.div
          //initial={{ right: "37rem" }}
          //whileInView={{ right: "35rem" }}
          //transition={transition}
          className="calories"
        >
          <img src={Calories} alt="" className="calories-img" />
          <div>
            <span className="calories-burned">Calories Burned</span>
            <span>270 kcal</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;